export const infoKey = btoa('phoneInfo');
export const forgotScreen = btoa('forgot');
export const userId = btoa('userId');
export const userName = btoa('userName');
export const organizerRequest = btoa('organizerRequest');

export const email = btoa('email');
export const password = btoa('password');
export const otpSent = btoa('otpSent');


export const permission = btoa('permission');
export const sessionId = btoa('sessionId');
export const couponTime = btoa('couponTime');
export const isRememberEmail = btoa('isRememberEmail');
export const isRememberPswd = btoa('isRememberPswd');

export const phoneNumber = btoa('phoneNumber');
export const profilePic = btoa('profilePic');
