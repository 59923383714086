import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { isRememberEmail, isRememberPswd } from 'src/app/constants/storage-keys';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  forForgotInfo = {} || null;
  faqEditData:any;
  canPageLeave$= new BehaviorSubject<boolean>(false);

  constructor(private _router: Router) {}

  setRememberData(formValues: any) {
    localStorage.setItem('isRemember', 'true');
    localStorage.setItem(isRememberEmail, btoa(formValues.email));
    localStorage.setItem(isRememberPswd, btoa(formValues.password));
    return;
  }

  getRememberData(key: any) {
    const item = localStorage.getItem(key);
    return atob(`${item}`);
  }

  isRemember() {
    return localStorage.getItem('isRemember');
  }

  removeRememberMeData() {
    localStorage.removeItem('isRemember');
    localStorage.removeItem(isRememberEmail);
    localStorage.removeItem(isRememberPswd);
    return
  }

  eventStatus(startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string): string {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);

    const startDate: Date = new Date(startDateStr);
    const startTime: Date = new Date(`1970-01-01 ${startTimeStr}`);
    startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);

    const endDate: Date = new Date(endDateStr);
    const endTime: Date = new Date(`1970-01-01 ${endTimeStr}`);
    endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    if (today < startDate) {
        return "Upcoming";
    } else if (today > endDate) {
        return "Completed";
    } else {
        return "Ongoing";
    }
}
removeDuplicates<T>(arr: T[], uniqueKey: string) {
  const uniqueKeysSet = new Set<any>();

  const resultArray = arr?.filter((obj: any) => {
    // Check if the object is not undefined or null and has the specified uniqueKey
    if (obj && obj.hasOwnProperty(uniqueKey)) {
      const objKey = obj[uniqueKey];

      if (!uniqueKeysSet.has(objKey)) {
        // Add the object's key to the set and keep the object
        uniqueKeysSet.add(objKey);
        return true;
      }
    }
    // If the object is undefined or null, or doesn't have the specified uniqueKey, filter it out
    return false;
  });

  return resultArray;
}

getTimezoneAbbreviation(timezone: string): string {
  return moment.tz(timezone).format('z');
}

getCurrentTimezoneAbbreviation(): string {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return moment.tz(timezone).format('z');
}

getCurrentTime(): string {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();

  return `${hours}:${minutesStr} ${amPm}`;
}
  navigate(route: any, query = {}, isMergeQuery = false) {
    this._router.navigate(route, { queryParams: query, queryParamsHandling: isMergeQuery ? 'merge' : '' })
  }
  /**
 * @UNSUBSCRIPTION Unsubscribe all subscriptions to avoid memory leak
 */
  unsubscribe(subscriptions: Subscription[]) {
    subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
