import { Injectable } from '@angular/core';
import { StorageService } from '../../storage/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _storage: StorageService) { }
  authorization() {
    const token = this._storage.token;
    const auth: any = {
      token: token ? token : '',
      Authorization: `Basic ${btoa('my_mylz_2024' + ":" + "jZ15i7H9wtcYSf3S")}`,
      secret_key: environment.SECRET_KEY,
      timezone: this.getTimeZone(),
      platform: 'WEB',
      ip: '',
      latitude: '',
      longitude: '',
    };
    if (!token) {
      delete auth['token'];
    }
    return auth;
  }
  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
