// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_PATH: 'https://mylzdevadmin.appskeeper.in/',
  SECRET_KEY: 'eR@a~C5OWN#8Q6xov?&)LF:Tz/n?jM%S}PN7ECIK_vS|dLT~KU"!x:NexkiAy?7',

  s3: {
    // AWS_ACCESS_KEY_ID: 'AKIAQUD4MO3QTLESWCDP',
    // AWS_SECRET_ACCESS_KEY: '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',
    AWS_DEFAULT_REGION: 'us-east-1',
    AWS_BUCKET: 'app-development/Mylz-app/'
  }
};

// S3 bucket
// S3 Bucket---appinventiv-development
// Url---https://appinventiv-development.s3.amazonaws.com/
// Region---us-east-1
// access key = AKIA6DQMUBGGY6CUWRG4
// secret access key = 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
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
